import { Alert, Box } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import { Text } from 'modules/theme/components/text/Text';
// eslint-disable-next-line no-restricted-imports
import { SnackbarContent, SnackbarMessage } from 'notistack';
import { FC, forwardRef, FunctionComponent, SVGProps } from 'react';
import InfoIcon from 'modules/theme/components/icons/basic/Info.svg';
import Keyhole from 'modules/theme/components/icons/basic/Keyhole.svg';
import CheckIcon from 'modules/theme/components/icons/basic/Check4.svg';
import ErrorIcon from 'modules/theme/components/icons/basic/Error2.svg';

interface FlashMessageContentProps {
    message: SnackbarMessage;
    variant?: 'success' | 'info' | 'error' | 'basic';
    customIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const FlashMessageContent: FC<FlashMessageContentProps> = forwardRef<
    HTMLDivElement,
    FlashMessageContentProps
>(({ message, variant, customIcon }, ref) => {
    let iconSvg: FunctionComponent<SVGProps<SVGSVGElement>> = Keyhole;

    if (variant === 'success') iconSvg = CheckIcon;

    if (variant === 'info') iconSvg = InfoIcon;

    if (variant === 'error') iconSvg = ErrorIcon;

    if (variant === 'basic' && customIcon) iconSvg = customIcon;

    const icon = <Box className='wrapper'>
        <Svg
            icon
            width={20}
            height={20}
            component={iconSvg}
        />
    </Box>;

    return <SnackbarContent ref={ref} style={{ minWidth: '150px', justifyContent: 'center', }}>
        <Alert icon={icon} severity={variant}>
            <Box>
                <Text variant='label1' semibold>
                    {message}
                </Text>
            </Box>
        </Alert>
    </SnackbarContent>;
});

FlashMessageContent.displayName = 'FlashMessageContent';
