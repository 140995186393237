import { FC } from 'react';
import { Box } from '@mui/material';
import Image from 'components/image/Image';
import ProfiClient from 'modules/theme/components/assets/badges/svg/ProfiClient.svg?url';

export const ProfiBadge: FC = () => {
    return (
        <Box>
            <Image alt='' width={40} height={40} src={ProfiClient} />
        </Box>
    );
};
