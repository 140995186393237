import { FC, ReactNode } from 'react';
import { Card } from '@mui/material';

interface IInputsMergedWrapper {
    children?: ReactNode[];
}

export const InputsMergedWrapper: FC<IInputsMergedWrapper> = ({ children }) => {

    return <Card
        className='UCRealEstateDesignSystem-InputsMerged'
    >
        {children}
    </Card>;
};