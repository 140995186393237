import { FunctionComponent, SVGProps, useCallback } from 'react';
// eslint-disable-next-line no-restricted-imports
import { SnackbarMessage, useSnackbar } from 'notistack';
import { FlashMessageContent } from 'modules/theme/components/flashMessage/SnackbarFlashMessageContent';

interface IReturn {
    success: (message: SnackbarMessage) => void;
    info: (message: SnackbarMessage) => void;
    error: (message: SnackbarMessage) => void;
    basic: (
        message: SnackbarMessage,
        icon: FunctionComponent<SVGProps<SVGSVGElement>>
    ) => void;
}

const useNotification = (): IReturn => {
    const { enqueueSnackbar } = useSnackbar();

    const success = useCallback(
        (message: SnackbarMessage) => {
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                content: (key, snackMessage) => (
                    <FlashMessageContent
                        variant='success'
                        message={snackMessage}
                    />
                ),
            });
        },
        [enqueueSnackbar]
    );

    const info = useCallback(
        (message: SnackbarMessage) => {
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                content: (key, snackMessage) => (
                    <FlashMessageContent
                        variant='info'
                        message={snackMessage}
                    />
                ),
            });
        },
        [enqueueSnackbar]
    );

    const error = useCallback(
        (message: SnackbarMessage) => {
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                content: (key, snackMessage) => (
                    <FlashMessageContent
                        variant='error'
                        message={snackMessage}
                    />
                ),
            });
        },
        [enqueueSnackbar]
    );

    const basic = useCallback(
        (
            message: SnackbarMessage,
            icon?: FunctionComponent<SVGProps<SVGSVGElement>>
        ) => {
            enqueueSnackbar(message, {
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                },
                content: (key, snackMessage) => (
                    <FlashMessageContent
                        customIcon={icon}
                        variant='basic'
                        message={snackMessage}
                    />
                ),
            });
        },
        [enqueueSnackbar]
    );

    return {
        success,
        info,
        error,
        basic,
    };
};

export default useNotification;
